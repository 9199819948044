@use '../../styles/mixins';

.container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 6.4rem 3.2rem;

  @include mixins.mobile {
    padding: 6.4rem 1.6rem 3.2rem;
  }
}
