@use '../mixins';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

html {
  // This defines what 1rem is
  font-size: 62.5%; // 10px
  font-family: 'DM Sans', sans-serif;
}

::selection {
  background-color: var(--secondary-color);
  color: var(--white-color);
}
