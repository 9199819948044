@use '../../styles/mixins';

.about {
  flex: 1;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);

  .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    width: 100%;
    padding: 6.4rem 3.2rem;

    @include mixins.mobile {
      padding: 6.4rem 1.6rem 3.2rem;
    }
  }

  h2 {
    font-size: 6.4rem;
    font-weight: 800;
    text-align: center;
    color: var(--black-color);
    text-transform: capitalize;

    span {
      color: var(--secondary-color);
    }

    @include mixins.xl-desktop {
      font-size: 4.4rem;
    }

    @include mixins.mobile {
      font-size: 3.2rem;
    }
  }

  .profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 3.2rem;

    .profileItem {
      width: 37rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      margin: 3.2rem 6.4rem;

      img {
        width: 100%;
        height: 32rem;
        border-radius: 1.5rem;

        object-fit: cover;
      }

      h2 {
        font-size: 3.2rem;
        font-weight: 800;
        color: var(--black-color);
        text-align: left;
        margin-top: 2rem;

        @include mixins.xl-desktop {
          font-size: 1.6rem;
        }

        @include mixins.mobile {
          font-size: 1.45rem;
        }
      }

      p {
        font-size: 2.8rem;
        text-align: left;
        color: var(--gray-color);
        line-height: 1.5;
        margin-top: 1rem;

        @include mixins.xl-desktop {
          font-size: 1.3rem;
        }
      }

      @include mixins.xl-desktop {
        width: 19rem;
        margin: 3.2rem;

        img {
          height: 17rem;
        }
      }
    }
  }
}
