*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  scroll-behavior: smooth;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  margin: inherit;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  &::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  &:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
