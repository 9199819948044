@use '../../styles/mixins';

.works {
  flex: 1;
  width: 100%;
  flex-direction: column;
  h2 {
    font-size: 6.4rem;
    font-weight: 800;
    text-align: center;
    color: var(--black-color);
    text-transform: capitalize;

    span {
      color: var(--secondary-color);
    }

    @include mixins.xl-desktop {
      font-size: 4.4rem;
    }

    @include mixins.mobile {
      font-size: 3.2rem;
    }
  }

  .workFilter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    margin: 6.4rem 0 3.2rem;

    .workFilterItem {
      display: flex;
      justify-content: center;
      align-items: center;

      border: none;
      padding: 1.6rem 3.2rem;
      border-radius: 1.4rem;
      background-color: #fff;
      color: #000;
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease;
      margin: 0.8rem;

      &:hover {
        background-color: var(--secondary-color);
        color: #fff;
      }

      @include mixins.xl-desktop {
        padding: 0.8rem 1.6rem;
        border-radius: 0.8rem;
      }

      p {
        font-size: 2.8rem;
        text-align: left;
        color: var(--gray-color);
        line-height: 1.5;
        margin-top: 1rem;

        @include mixins.xl-desktop {
          font-size: 1.3rem;
        }
      }
    }

    .itemActive {
      background-color: var(--secondary-color);
      color: #fff;
    }
  }

  .workPortfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .workItem {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      width: 47rem;
      margin: 3.2rem;
      padding: 2rem;
      border-radius: 1.2rem;
      background-color: #fff;
      color: #000;

      transition: all 0.3s ease;

      &:hover {
        box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.2);

        & .workHover {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }

      @include mixins.xl-desktop {
        width: 27rem;
        padding: 1.6rem;
        border-radius: 0.8rem;
      }

      @include mixins.mobile {
        width: 100%;
        margin: 1rem;
      }

      .workImg {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 35rem;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          border-radius: 0.8rem;
          object-fit: cover;
        }

        @include mixins.xl-desktop {
          height: 23rem;
        }

        .workHover {
          display: flex;
          justify-content: center;
          align-items: center;

          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          border-radius: 0.8rem;
          transition: all 0.3s ease;

          .workHoverLink {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            margin: 1.6rem;
            font-weight: 800;
            cursor: pointer;
            transition: all 0.3s ease;

            svg {
              width: 50%;
              height: 50%;
              color: var(--white-color);
            }

            &:hover {
              background-color: rgba(0, 0, 0, 0.7);
              transform: scale(1.1);
            }
          }
        }
      }

      .workContent {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        padding: 0.8rem;
        width: 100%;
        position: relative;

        h4 {
          font-size: 3.2rem;
          font-weight: 800;
          color: var(--black-color);
          text-align: left;

          margin-top: 1.6rem;
          line-height: 1.5;

          @include mixins.xl-desktop {
            font-size: 1.6rem;
          }

          @include mixins.mobile {
            font-size: 1.45rem;
          }
        }

        p {
          font-size: 2.8rem;
          text-align: left;
          color: var(--gray-color);
          line-height: 1.5;

          @include mixins.xl-desktop {
            font-size: 1.3rem;
          }
        }

        .workTag {
          display: flex;
          justify-content: center;
          align-items: center;

          position: absolute;
          padding: 0.8rem 1.6rem;
          border-radius: 1rem;
          background-color: #fff;
          top: -2.5rem;

          p {
            font-size: 2.8rem;
            text-align: left;
            color: var(--gray-color);
            line-height: 1.5;

            @include mixins.xl-desktop {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
}
