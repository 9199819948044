@use '../../styles/mixins';

.navigation {
  display: flex;
  flex-direction: column;

  position: fixed;
  top: 50%;
  right: 1rem;
  padding: 1.6rem;
  transform: translateY(-50%);

  .navigationDot {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #cbcbcb;
    margin: 0.8rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--secondary-color);
    }

    @include mixins.xl-desktop {
      width: 1rem;
      height: 1rem;
    }
  }
  @include mixins.mobile {
    display: none;
  }
}
